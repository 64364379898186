










import Vue from "vue";
import NewsCard from "@/components/news/NewsCard.vue";
import { News } from "@/types";

export default Vue.extend({
  name: "NewsView",
  components: { NewsCard },
  created: function () {
    this.news = this.$helpers.loadNews();
  },
  data() {
    return {
      news: [] as News[],
    };
  },
});
